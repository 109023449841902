export const transactionsTableColumns = [
    {
        title: 'USER',
        dataIndex: 'username',
        key: 'username',
        width: '20%'
    },
    {
        title: 'AMOUNT',
        dataIndex: 'amount',
        key: 'amount',
        width: '20%'
    },
    {
        title: 'DATE',
        dataIndex: 'date',
        key: 'date',
        width: '20%'
    },
    {
        title: 'TIME',
        dataIndex: 'time',
        key: 'time',
        width: '20%'
    },
    {
        title: 'TRANSACTION ID',
        dataIndex: 'id',
        key: 'id',
        width: '20%'
    }
];
