import React from 'react';
import { Avatar } from 'v2/components/ui/Avatar';
import { CopyableText } from 'v2/components/ui/typography/CopyableText';
import { Text } from 'v2/components/ui/typography/Text';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { MarginWrapper } from 'v2/components/wrappers/MarginWrapper/styles';
import { routePaths } from 'v2/constants/routes';
import { LinkItem } from 'v2/pages/Transactions/styles';
import { getFormattedDate } from 'v2/utils/datetime';
import { formatMoney } from 'v2/utils/formats';

export const getTransactionsTableData = (transactions: YECM.AdminTransactionResponse[] | null | undefined) =>
    transactions?.map(item => {
        const amount = formatMoney({ value: item.amount, signDisplay: 'always' });
        const date = getFormattedDate(item.utcCreated, 'dd-MMM-yyyy');
        const time = getFormattedDate(item.utcCreated, 'hh:mm:ss');

        return {
            key: item.transactionId,
            username: (
                <LinkItem to={`${routePaths.users}/${item.remoteUserId}`}>
                    <Section alignCenter>
                        <MarginWrapper marginRight="8px">
                            <Avatar size="32px" />
                        </MarginWrapper>
                        <Text isSemibold size="-2">
                            @{item.remoteUsername}
                        </Text>
                    </Section>
                </LinkItem>
            ),
            amount: (
                <Section justifyCenter>
                    <Text size="-1">{amount}</Text>
                </Section>
            ),
            date: (
                <Section justifyCenter>
                    <Text isUppercase size="-1">
                        {date}
                    </Text>
                </Section>
            ),
            time: (
                <Section justifyCenter>
                    <Text size="-1">{time}</Text>
                </Section>
            ),
            id: (
                <Section justifyCenter>
                    <CopyableText isSemibold size="-2" subject={item.transactionId} text={item.transactionId} />
                </Section>
            )
        };
    });
