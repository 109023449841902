import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { grey } from 'v2/constants/styles/colors';
import { media } from 'v2/constants/styles/media';

export const ContentWrapper = styled(Section)`
    padding: 16px;

    ${media.laptopSmall(`
        padding: 24px 32px 24px 16px;
    `)};
`;

export const LinkItem = styled(Link)`
    padding: 4px;
    display: inline-block;
    width: 100%;

    &:hover {
        background-color: ${grey[100]};
    }
`;
